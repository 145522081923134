import React from 'react';

import './SpecialMenu.css';
import {images, data} from '../../constants';

import {SubHeading, MenuItem} from '../../components';

const SpecialMenu = () => (
  <div className="app__specialMenu flex__center section__padding" id="menu">
    <div className="app__specialMenu-title">
      <SubHeading title="Menu That Fits Your Palatte" />
      <h1 className="headtext__cormorant">Today's Special</h1>
    </div>
    <div className="app__specialMenu-menu">
      <div className="app__specialMenu-menu_wine flex__center">
        <p className="app__specialMenu-menu_heading">Wine & Bear</p>
        <div className="app__specialMenu-menu_items">
          {data.wines.map((wine,index) => (
            <MenuItem key={wine.title + index} title={wine.title} price={wine.price} tags = {wine.tags} />
          ))}
        </div>
      </div>

      <div className="app__specialMenu-menu-img">
        <img src={images.menu} alt="menuimage" />
      </div>

      <div className="app__specialMenu-menu_cocktail flex__center">
        <p className="app__specialMenu-menu_heading">Cocktails</p>
        <div className="app__specialMenu-menu_items">
          {data.cocktails.map((cocktail,index) => (
            <MenuItem key={cocktail.title + index} title={cocktail.title} price={cocktail.price} tags = {cocktail.tags} />
          ))}
        </div>
      </div>
    </div>

    <div style={{marginTop: '1.5rem'}}>
      <button type="button" className="custom__button">View More</button>
    </div>
  </div>
);

export default SpecialMenu;
